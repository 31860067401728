/* eslint-disable */
import $ from 'jquery';
import ymaps from 'ymaps';
import '@fancyapps/fancybox';
import 'jquery-mask-plugin';
import AOS from 'aos';

window.$ = $;
window.jQuery = $;

AOS.init({
    duration: 600,
    offset: 320
});

// faq
$(() => {
    const isMob = window.matchMedia('(max-width: 991px)').matches;
    $('.faq__items__item').on('click', function() {
        $('.faq__items__item').removeClass('active');
        $('.faq__items__item_desc').fadeOut(isMob ? 0 : 300);
        $(this).addClass('active');
        $(this).find('.faq__items__item_desc').fadeIn(isMob ? 0 : 300);
    });
});

// map
$(() => {
    const map = $('#map');
    if (map.length) {
        ymaps.load().then((maps) => {
            const coords = [59.224493, 39.864205];

            const myMap = new maps.Map('map', {
                center: coords,
                zoom: 17,
                controls: []
            }, {
                searchControlProvider: 'yandex#search',
            });
            const myPlacemark = new maps.Placemark(coords, {
                balloonContent: 'г. Вологда, ул. Благовещенская, д. 89'
            }, {
                preset: 'islands#dotIcon',
                iconColor: '#5da8d2'
            });

            myMap.geoObjects.add(myPlacemark);
            myMap.behaviors.disable('scrollZoom');
            map.addClass('noZoom');
            myMap.events.add('click', () => {
                if (map.hasClass('noZoom')) {
                    map.removeClass('noZoom').addClass('yesZoom');
                    myMap.behaviors.enable('scrollZoom');
                } else {
                    map.removeClass('yesZoom').addClass('noZoom');
                    myMap.behaviors.disable('scrollZoom');
                }
            });

            const isMobile = {
                Android: () => (navigator.userAgent.match(/Android/i)),
                BlackBerry: () => (navigator.userAgent.match(/BlackBerry/i)),
                iOS: () => (navigator.userAgent.match(/iPhone|iPad|iPod/i)),
                Opera: () => (navigator.userAgent.match(/Opera Mini/i)),
                Windows: () => (navigator.userAgent.match(/IEMobile/i)),
                any: () => (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows())
            };

            if (isMobile.any()) {
                myMap.behaviors.disable('drag');
            }
        }).catch(error => console.log('Failed to load Yandex Maps', error));
    }
});

// mask
$(() => {
    $('.popup_form_tel').mask('+7(000) 000-00-00');
});

// popup
$(() => {
    const overlay = $('.overlay');
    const popupForm = $('.popup.form');
    const popupInfo = $('.popup.info');
    const close = $('.popup_close');
    $('.target-callback').on('click', function(e) {
        e.preventDefault();
        const text = $(this).text();
        const successText = $(this).data('success');
        $('.popup_success').text(successText);
        $('.popup__form_title').text(text);
        $('.popup__form input[name=title]').val(text);
        $('.popup__form input[name=subject]').val(`Письмо с ecovey.ru: «${text}»`);

        overlay.removeClass('fadeOut').addClass('fadeIn');
        popupForm.removeClass('fadeOut').addClass('fadeIn');
    });
    close.on('click', () => {
        overlay.removeClass('fadeIn').addClass('fadeOut');
        popupForm.removeClass('fadeIn').addClass('fadeOut');
        setTimeout(function() {
            $('.popup__form').css({ display: 'flex' });
            $('.popup_success').css({ display: 'none' });
        }, 500);
    });
    overlay.on('click', () => {
        overlay.removeClass('fadeIn').addClass('fadeOut');
        popupForm.removeClass('fadeIn').addClass('fadeOut');
        setTimeout(function() {
            $('.popup__form').css({ display: 'flex' });
            $('.popup_success').css({ display: 'none' });
        }, 500);
    });
    $('.target-info').on('click', function(e) {
        e.preventDefault();

        overlay.removeClass('fadeOut').addClass('fadeIn');
        popupInfo.removeClass('fadeOut').addClass('fadeIn');
    });
    close.on('click', () => {
        overlay.removeClass('fadeIn').addClass('fadeOut');
        popupInfo.removeClass('fadeIn').addClass('fadeOut');
    });
    overlay.on('click', () => {
        overlay.removeClass('fadeIn').addClass('fadeOut');
        popupInfo.removeClass('fadeIn').addClass('fadeOut');
    });
});

// mob-menu

$(() => {
    const burger = $('.burger');
    const menu = $('.mob-menu');
    const link = $('.mob-menu__menu a');
    const overlay = $('.overlay');

    burger.on('click', function() {
        if($(this).hasClass('active')) {
            $(this).removeClass('active').addClass('not-active');
            overlay.removeClass('fadeIn').addClass('fadeOut');
            menu.removeClass('open');
        } else {
            $(this).removeClass('not-active').addClass('active');
            overlay.removeClass('fadeOut').addClass('fadeIn');
            menu.addClass('open');
        }
    });
    overlay.on('click', () => {
        overlay.removeClass('fadeIn').addClass('fadeOut');
        menu.removeClass('open');
        burger.removeClass('active').addClass('not-active');
    });
    link.on('click', () => {
        overlay.removeClass('fadeIn').addClass('fadeOut');
        menu.removeClass('open');
        burger.removeClass('active').addClass('not-active');
    });
});

// scroll
$(() => {
    $("[scrollto]").click(function(e) {
        e.preventDefault();
        const idBlock = $(this).attr('scrollto');
        $("html, body").animate({
            scrollTop: $(idBlock).offset().top
        }, 1500);
    });
});

// btn up
$(() => {
    const btn = $('#up');
    $(window).on('scroll', () => {
        if(document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
            btn.fadeIn();
        } else {
            btn.fadeOut();
        }
    });

    btn.on('click', () => {
        $("html, body").animate({
            scrollTop: 0
        }, 1500);
    });
});